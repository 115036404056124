import * as React from "react";
import { Layout } from "../components/layout";
import LocalBuisiness from "../components/localBuisiness";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <Layout title="Home">
        <LocalBuisiness />
        <main className="container">
          <div>
            <h1 className="my-3">
              Herzlich Willkommen
            </h1>
            <p>
              Mein Name ist Margit Westermann und ich züchte seit über 15 Jahren
              Golden Retriever. Auf diesen Seiten erfahren Sie einiges
              über mich, meine Zucht und meine Golden Mädels.
            </p>
            <p>
              Im Namen meiner Zucht sind mein erster Eindruck von dieser
              wundervollen Hunderasse und ihr Ursprungsort vereint. So kam ich
              auf " Golden Eyes of Munroe ". - Munroe ist die Bezeichnung von
              Bergen in Schottland, die höher als 3000 ft (914,4 m) sind und
              deren Berggipfel eine „gewisse Eigenständigkeit“ aufweisen. Na
              dann..........
            </p>
            <p>Ich wünsche Ihnen viel Spaß beim Stöbern auf meinen Seiten.</p>
            <div className="mt-5">
              <h3>
                Ihre Margit Westermann <br /> und
                <br /> die MÄDELS
              </h3>
            </div>
            {/*<p class="mt-3">Die Babys sind da! Näheres unter <Link to="/zucht/wurfplanung/">Wurfplanung</Link></p>*/}
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
